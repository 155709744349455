import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';


const LoadingDots = ({
	className, showText, text, numDots, color,
}) => {

	const dots = [];
	for (let i = 0; i < numDots; i++) {
		dots.push(<div className="loading-dots__dot" key={ i } />);
	}

	return (
		<div className={ clsx('loading-dots__wrapper', className) }>
			<div className="loading-dots">{dots}</div>
			{showText && (
				<legend className="text-center">
					{typeof text !== 'undefined' && text.length > 0 ? (
						<>
							{`${text}`}
							<span key="ellipsis">&hellip;</span>
						</>
					) : (
						text
					)}
				</legend>
			)}
		</div>
	);
};

LoadingDots.propTypes = {
	className: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
	showText: PropTypes.bool,
	color: PropTypes.string,
	numDots: PropTypes.number,
};
LoadingDots.defaultProps = {
	className: '',
	text: '',
	showText: true,
	color: '',
	numDots: 3,
};

export default LoadingDots;
