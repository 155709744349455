import React    from 'react';
import ReactDOMClient from 'react-dom/client';

import DocEvents           from 'UTILS/events';
import STPApp         from './stp.app';

import { capitalize } from 'HELPERS/text';
import { hasMultipleValues, hasSomeValues } from 'HELPERS/array';
import convertToPixel from 'UTILS/convertToPixel';
import genereateStyleObj from 'UTILS/genereateStyleObj';
import validHex from 'UTILS/validhex';
import validHtml from 'UTILS/validhtml';
import { isNumber } from 'HELPERS/validator';

import Context from 'UTILS/Context';
import Mixpanel from 'UTILS/Vendor/Mixpanel';
import Google from 'UTILS/Vendor/Google';
import Rollbar from 'UTILS/Vendor/Rollbar';
import Session from 'UTILS/Session';
import LokaliseLanguage from 'UTILS/LokaliseLanguage';
import Emitter from 'UTILS/Emitter';

import PreferabliAnalytics from 'UTILS/Vendor/analytics';
import IntegrationsApi from 'API/IntegrationsApi';

"use strict";

class PreferabliSTP {
	constructor(element, options) {
		const defaults = {
			gallery: {
				type: 'carousel',
				productsToDisplay: 4,
			},
			display: {
				separator: null,
				layout: 'row',
				use_preferabli_logo: false,
				logo_placement: 'top',
				logo_option: 1,
				logo_inline_style: '',
				logo_class: '',
			},
			helpTooltip: false,
			devMode: false,
			openNewTab: false,
			showDecimalPlaces: true,
			numDecimalPlaces: 2,
			showLink: true,
			customText: null,
			renderProductCards: null,
			onRenderComplete: null,
			productCardsContainer: null,
			lang: 'en',
		};

		if(Object.keys(options).length){
			if(Object.keys(options).includes('showViewButton')){
				options.showLink = options.showViewButton;
				delete options.showViewButton;
			}

			if (options.display) {

				if(Object.keys(options.display).includes('preferabli_logo')){
					options.display.use_preferabli_logo = options.display.preferabli_logo;
					delete options.display.preferabli_logo;
				}
			
				if(Object.keys(options.display).includes('placement_powered_by_winering')){
					options.display.logo_placement = options.display.placement_powered_by_winering;
					delete options.display.placement_powered_by_winering;
				}

				if(Object.keys(options.display).includes('powered_by_placement')){
					options.display.logo_placement = options.display.powered_by_placement;
					delete options.display.powered_by_placement;
				}

				if(Object.keys(options.display).includes('powered_by_logo_option')){
					options.display.logo_option = options.display.powered_by_logo_option;
					delete options.display.powered_by_logo_option;
				}


				if(!Object.keys(options.display).includes('logo_placement')){
					options.display.logo_placement = 'top';
				}

				if(!Object.keys(options.display).includes('logo_option')){
					options.display.logo_option = defaults.display.logo_option;
				}

				if(!Object.keys(options.display).includes('layout')){
					options.display.layout = 'row';
				}

				if(Object.keys(options.display).includes('layout')){
					if(options.display.layout === 'list'){
						options.display.powered_by_placement = 'bottom';

						if(options.renderProductCards){
							delete options.renderProductCards;
							throw 'Unable to use renderProductCards with this layout mode, please try using row or column mode instead.';
						}
					}
				}

				if(Object.keys(options.display).includes('logo_class') && String(options.display.logo_class).length){
					options.display.logoClass = options.display.logo_class;
					delete options.display.logo_class;
				} else {
					options.display.logoClass = defaults.display.logo_class;
					delete options.display.logo_class;
				}

				if(Object.keys(options.display).includes('logo_inline_style') && String(options.display.logo_inline_style).length){
					options.display.logoInlineStyle = genereateStyleObj(options.display.logo_inline_style);
					delete options.display.logo_inline_style;
				} else {
					options.display.logoInlineStyle = genereateStyleObj(options.display.logo_inline_style);
					delete options.display.logo_inline_style;
				}

				if(Object.keys(options.display).includes('logo_before') && String(options.display.logo_before).length){
					if(validHtml(options.display.logo_before)){
						options.display.logoBefore = options.display.logo_before;
						delete options.display.logo_before;
					} else {
						throw 'Display option:logo_before, requires to be a valid html string.';
					}
				}

				if(Object.keys(options.display).includes('logo_after') && String(options.display.logo_after).length){
					if(validHtml(options.display.logo_after)){
						options.display.logoAfter = options.display.logo_after;
						delete options.display.logo_after;
					} else {
						throw 'Display option:logo_after, requires to be a valid html string.';
					}
				} 

				if(Object.keys(options.display).includes('seperator')){
					options.display.separator = {...options.display.seperator};
					delete options.display.seperator;
				}

				if(Object.keys(options.display).includes('separator')){

					// convert top|bottom|both => show
					if(hasSomeValues(Object.keys(options.display.separator),['top','bottom','both'])){
						if(Object.keys(options.display.separator).filter((key) => ['top', 'bottom'].includes(key)).length){
							const _keys = Object.keys(options.display.separator).filter((key) => ['top', 'bottom'].includes(key));
							if(['top', 'bottom'].every((key) => _keys.includes(key))){
								options.display.separator.show = 'both';
								delete options.display.separator.bottom;
								delete options.display.separator.top;
							}
						} else if(options.display.separator.top || options.display.separator.bottom || options.display.separator.both){
							if(options.display.separator.top) {
								options.display.separator.show = 'top';
								delete options.display.separator.top;
							} 
							if(options.display.separator.bottom) {
								options.display.separator.show = 'bottom';
								delete options.display.separator.bottom;
							} 
							if(options.display.separator.both) {
								options.display.separator.show = 'both';
								delete options.display.separator.both;
							} 
						}
					}

					if(options.display.separator.style && Object.keys(options.display.separator.style).length){
						//deal with top and bottom objects
						if(Object.keys(options.display.separator.style).filter((key) => ['top', 'bottom'].includes(key)).length){
							const tmp = ['top', 'bottom'].reduce((obj, item) => {

								if(!options.display.separator.style[item]) return obj;

								const styleKeys = Object.keys(options.display.separator.style[item]);

								if(options.display.separator.style[item] && styleKeys.length){
									obj[`border${capitalize(item)}`] = {inline:[], style: null};

									if(styleKeys.filter((key) => ['border_width','border_color','border_style'].includes(key)).length){
										if(hasMultipleValues(styleKeys, ['border_width','border_color','border_style'])){
											const filterKeys = styleKeys.filter((key) => ['border_width','border_style','border_color'].includes(key));
											const combinedBorderArr = filterKeys.reduce((arr, borderProp) => {
												let value = options.display.separator.style[item][borderProp];

												switch(borderProp){
												 case 'border_width':
													value = convertToPixel(String(value));
													arr.push(value);
												 break;
												 case 'border_color':
												 	if(validHex(value)){
													 	arr.push(value);
												 	} else {
												 		throw `${capitalize(item)} border color is not a valid color, please enter a valid hex value, ie: #fff, #ffffff`;
												 	}
												 break;
												 default:
													 arr.push(value);
												}

												delete options.display.separator.style[item][borderProp];

												return arr;
											}, []);

											obj[`border${capitalize(item)}`].style = combinedBorderArr.join(' ');

										} else {
											['border_width','border_color','border_style'].forEach((borderProp) => {
												if(!options.display.separator.style[item][borderProp]) return;
												if(options.display.separator.style[item][borderProp]){

													let value = options.display.separator.style[item][borderProp];
													switch(borderProp){
													 case 'border_width':
														value = convertToPixel(String(value));
														obj[`border${capitalize(item)}`].inline.push({borderTopWidth: value});
													 break;
													 case 'border_color':
													 	if(validHex(value)){
														 	obj[`border${capitalize(item)}`].inline.push({borderTopColor: value});
													 	} else {
													 		throw `${capitalize(item)} border color is not a valid color, please enter a valid hex value, ie: #fff, #ffffff`;
													 	}
													 break;
													 default:
														 obj[`border${capitalize(item)}`].inline.push({borderTopStyle: value});
													}
													delete options.display.separator.style[item][borderProp];

												}
											})
										}
									}
									if(styleKeys.filter((key) => ['spacing_top','spacing_bottom',].includes(key)).length){
										if(options.display.separator.style[item].spacing_top){
											const tmpSpacingTop = options.display.separator.style[item].spacing_top;
											delete options.display.separator.style[item].spacing_top;
											obj[`border${capitalize(item)}`].inline.push({ marginTop : tmpSpacingTop});
										}
										if(options.display.separator.style[item].spacing_bottom){
											const tmpSpacingBottom = options.display.separator.style[item].spacing_bottom;
											delete options.display.separator.style[item].spacing_bottom;
											obj[`border${capitalize(item)}`].inline.push({ marginBottom : tmpSpacingBottom});
										}
									}

								}
								delete options.display.separator.style[item];

								return obj;
							}, {});
							Object.assign(options.display.separator.style, tmp);
						}

						if(Object.keys(options.display.separator.style).filter((key) => ['border_width','border_color','border_style', 'spacing_top', 'spacing_bottom'].includes(key)).length){

							const tmp = {};

							if(hasMultipleValues(Object.keys(options.display.separator.style), ['border_width','border_color','border_style'])){
								const filterKeys = Object.keys(options.display.separator.style).filter((key) => ['border_width','border_style','border_color'].includes(key));
								const combinedBorderArr = filterKeys.reduce((arr, borderProp) => {
									let value = options.display.separator.style[borderProp];

									switch(borderProp){
									 case 'border_width':
										value = convertToPixel(String(value));
										arr.push(value);
									 break;
									 case 'border_color':
									 	if(validHex(value)){
										 	arr.push(value);
									 	} else {
									 		throw `Border color is not a valid color, please enter a valid hex value, ie: #fff, #ffffff`;
									 	}
									 break;
									 default:
										 arr.push(value);
									}

									delete options.display.separator.style[borderProp];

									return arr;
								}, []);

								const styleString = combinedBorderArr.join(' ');
								if(['both', 'top', 'bottom'].includes(options.display.separator.show)){
									tmp.border = styleString;
								}
							} else {
								
								tmp.borderTop = { inline: [], style: null };

								['border_width','border_color','border_style'].forEach((borderProp) => {
									if(!options.display.separator.style[borderProp]) return;
									if(options.display.separator.style[borderProp]){

										let value = options.display.separator.style[borderProp];
										switch(borderProp){
										 case 'border_width':
											value = convertToPixel(String(value));
											tmp.borderTop.inline.push({borderTopWidth: value});
										 break;
										 case 'border_color':
										 	if(validHex(value)){
											 	tmp.borderTop.inline.push({borderTopColor: value});
										 	} else {
										 		throw `Border color is not a valid color, please enter a valid hex value, ie: #fff, #ffffff`;
										 	}
										 break;
										 default:
											 tmp.borderTop.inline.push({borderTopStyle: value});
										}
										delete options.display.separator.style[borderProp];

									}
								});
							}

							if(Object.keys(options.display.separator.style).filter((key) => ['spacing_top','spacing_bottom'].includes(key)).length){
								if(options.display.separator.style.spacing_top){
									const tmpSpacingTop = options.display.separator.style.spacing_top;
									delete options.display.separator.style.spacing_top;
									tmp.spacingTop = tmpSpacingTop;
								}
								if(options.display.separator.style.spacing_bottom){
									const tmpSpacingBottom = options.display.separator.style.spacing_bottom;
									delete options.display.separator.style.spacing_bottom;
									tmp.spacingBottom = tmpSpacingBottom;
								}
							}

							if(options.display.separator.style?.borderBottom){
								if(options.display.separator.style.borderBottom?.inline.length){
									Object.assign(options.display.separator.style.borderBottom.inline, tmp.borderTop.inline);
								}
							}

							Object.assign(options.display.separator.style, tmp);

						}


						if(options.display.separator.show === 'top' ||
							options.display.separator.show === 'bottom'){

							if(Object.keys(options.display.separator.style).filter((key) => ['border', 'borderTop', 'borderBottom'].includes(key)).length > 1){
								throw new Error('Only allowed to have 1 border parameter when show is assigned as top|bottom, border will apply to either top or bottom separator enabled unless specified.');
							}
						}
					}
					
				}

			}

			if (typeof options.onRenderComplete !== 'undefined' && !options.renderProductCards) {
				throw 'onRenderComplete callback function requires renderProductCards callback.';
			}
		}

		if(!options.element && element){
			options.element = document.querySelector(element);
		}


		const opts = { ...defaults, ...options };

		this.opts = opts;

		if(window.Rollbar) window.Rollbar.configure({enable: true});
		Google.init();
		Mixpanel.identify();
		Mixpanel.addGroup(this.opts.integration_id);
		Mixpanel.setGroup('integration_id', this.opts.integration_id);

		Context.setIntegrationId(this.opts.integration_id);
		Context.setLanguage(this.opts.lang);
		Context.setAppOptions(this.opts);

		this.render({
			...this.opts,
		});

		
	}

	handleError = (error = '') => {
		document.dispatchEvent(new CustomEvent(DocEvents.loadError, {detail: error}));
		document.dispatchEvent(new CustomEvent('preferabli.lttt.onError', {detail: error}));
		document.dispatchEvent(new CustomEvent('preferabli.stp.onError', {detail: error}));
	}

	getLTTT = (product_params) => {
		this.getProducts(product_params);
	}

 	getProducts = async (params = {}) => {
		const { collection_id } = this.opts;

		const param_keys = Object.keys(params);

		if (param_keys.length === 0) {
			this.handleError(error);
		}

		const check_params_values = Object.keys(params).every(
			(param) => typeof params[param] !== 'undefined' && params[param] !== null
		);

		if (!check_params_values) {
			throw "Either 'product_id' was invalid format. Please make sure that 'product_id' is a string or numeric format.";
			this.handleError(error);
		}

		if (check_params_values) {
			Context.setProductId(params.product_id);

			const products = await this.fetchLTTT({
				collection_id,
				...params,
			}).catch((error) => {

				PreferabliAnalytics.track('lttt results not loaded', {
					onlyMp: true,
					error: 'api',
				    ...params,
				});

				return (null);
			});

			if (products) {

				PreferabliAnalytics.track('lttt results returned', {
					onlyMp: true,
					number_of_products: Array.isArray(products.merchant_vintage_details) && products.merchant_vintage_details.length ? products.merchant_vintage_details.length : 0,
				    ...params,
				});

				const updateProducts = new CustomEvent(DocEvents.updateProducts, {
					detail: {
						products,
						product_id: params.product_id,
					},
				});
				document.dispatchEvent(updateProducts);
				document.dispatchEvent(new CustomEvent('preferabli.lttt.onComplete', { detail: '' }));
				document.dispatchEvent(new CustomEvent('preferabli.stp.onComplete', { detail: '' }));

			} 

			if(!products){
				this.handleError();
			}
		}
	}

	fetchLTTT = (options) => {
		const {
			collection_id, landing_url, product_id, price_max_percent, price_min_percent, limit
		} = options;

		let params = {
			collection_id,
		}

		if(product_id && String(product_id).length){
			params.merchant_variant_id = String(product_id);
		}

		if(landing_url && String(landing_url).length){
			params.landing_url = encodeURIComponent(String(landing_url));
		}

		if (price_max_percent && Number(price_max_percent) > 0) {
			params.price_max_percent = Number(price_max_percent);
		}
		if (price_min_percent && Number(price_min_percent) > 0) {
			params.price_min_percent = Number(price_min_percent);
		}

		if(limit && isNumber(String(limit))) params.limit = Number(limit);


		return IntegrationsApi.getProductLttt({
			...params,
		});
	}

	createAttchElement = (attachElem, newElement) => {
		var elementDiv = document.createElement('div');
		elementDiv.id = newElement.replace('#', '');

		if (typeof attachElem === 'string' || !(attachElem instanceof Element)) {
			if (document.querySelector(attachElem).nodeName === 'BODY') {
				const bodyEl = document.querySelector(attachElem);
				const firstScript = Object.values(document.querySelector('body').childNodes).find(
					(htmlTag) => htmlTag.nodeName === 'SCRIPT'
				);

				document.querySelector(attachElem).insertBefore(elementDiv, firstScript);
			}
			if (document.querySelector(attachElem)) {
				document.querySelector(attachElem).appendChild(elementDiv);
			}
		}
		if (attachElem instanceof Element) {
			attachElem.appendChild(elementDiv);
			resolve();
		}
		return elementDiv;
	}

	render = async (params) => {

		let _root;

		await LokaliseLanguage.load(this.opts.lang);  // load before rendering app

		const { element, ...renderParams } = params;

		const rootNode = (element && element instanceof Element) ? element : !document.querySelector('#lttt_placeholder')
				? this.createAttchElement('body', 'lttt_placeholder')
				: document.querySelector('#lttt_placeholder');


		_root = ReactDOMClient.createRoot(rootNode);

		_root.render(<STPApp { ...renderParams } />, () => {
			console.log('callback');
		});
	}
}

const fetchElement = (element) => document.getElementById(element) || document.querySelector(element);

window.PreferabliSTP = PreferabliSTP;
window.PreferabliLTTT = PreferabliSTP;
window.WRLikeThatTryThis = PreferabliSTP;

export default PreferabliSTP;
