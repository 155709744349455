import Context       from 'UTILS/Context';
import { underscore } from 'HELPERS/text';

class GoogleAnalyticsTracking {
	constructor() {
		this.analytics_id = __GOOGLE_ANALYTICS_ID__;
	}

	getGACode() {
		const analytics_id = gaConfig.trackers[Context.getIntegrationId()];
		this.analytics_id = __GOOGLE_ANALYTICS_ID__;
		return analytics_id;
	}

	createGA() {
		gtag('config', `G-${this.analytics_id || this.getGACode()}`);
	}

	ga_check() {
		const scriptCheck = () => {
			const all_scripts = Array.prototype.slice.call(document.scripts);
			return all_scripts.find((script) => script.id === 'pjs-stp-google-tag');
		};

		if (!scriptCheck()) {
			// adding
			(function(i, s, o, g, r, a, m) {
				a = s.createElement(o);
				m = s.getElementsByTagName(o)[0];
				a.async = 1;
				a.id = 'pjs-stp-google-tag';
				a.src = g;
				m.parentNode.insertBefore(a, m);

				r = s.createElement(o);
				r.text = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);}gtag('js', new Date());";
				m.parentNode.insertBefore(r, m);
			}(window, document, 'script', `//www.googletagmanager.com/gtag/js?id=${this.analytics_id}`));

			this.createGA();
			this.setGAClientId();
		} else {
			this.createGA();
			this.setGAClientId();
		}
	}

	setGAClientId() {
		gtag('get', `G-${this.analytics_id}`, 'client_id', (client_id) => {
			this.GAClientId = client_id;
		});
	}

	track(event, params = {}) {
		const GADefaults = {
			client_integration_id: `${Context.getIntegrationId()}`,
			client_hostname: `${window.location.hostname}`,
		};

		gtag(
			'event',
			`${underscore(event)}`,
			Object.assign(GADefaults, {
				...params,
			})
		);
	}

	init() {
		this.ga_check();
	}
}

const GoogleTracking = new GoogleAnalyticsTracking();
export default GoogleTracking;
