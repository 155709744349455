import clsx                                from 'clsx';

import PropTypes                           from 'prop-types';
import React, { Suspense, lazy, useState, useEffect } from 'react'
import logoPaths from './poweredby-paths';



const PoweredByLogo = (props) => {
  const [iconNotFound, setIsNotFound] = useState(false);
  const [logo, setLogo] = useState();

  useEffect(() => {
    if(props.logoNumber){
      const logo = Object.values(logoPaths).find((optLogo) => {
        return (optLogo.id === Number(props.logoNumber));
      });
      setLogo(logo);
    }
  }, [props.logoNumber]);

  const { ...svgProps } = props;

  const DynamicLoader = (params) => {
    const LazyComponent = params.path;
    if (!LazyComponent) {
      console.error('No SVG was found that match your query.', props.icon);
      setIsNotFound(true)
      return null
    }
    return (
      <Suspense fallback={ <g /> }>
        <LazyComponent { ...svgProps }/>
      </Suspense>
    )
  }

  if (iconNotFound) {
    return null
  }

  return (
    <>
    {logo && (
    <svg
      viewBox={`0 0 ${logo.viewBox}`}
      className={ clsx(
        'lttt__poweredby',
        logo.type === 'wordmark' && 'lttt__poweredby--wordmark',
        logo.type === 'logomark' && 'lttt__poweredby--logomark',
        props.className,
        ) }
    >
      <DynamicLoader {...logo} />
    </svg>
    )}
    </>
  );

};


PoweredByLogo.propTypes = {
  logoNumber: PropTypes.number.isRequired,
}

PoweredByLogo.defaultProps = {
  logoNumber: 1,
}

export default React.memo(PoweredByLogo)

