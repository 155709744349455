import Api             from './Api';

class Integrations extends Api {

getProductLttt = (params) => this.get(`lttt`, { ...params, return_details: true, }).then((res) => {
    if(res.merchant_vintage_details){
      res.products = res.merchant_vintage_details.map((_product) => ({
        ..._product,
        primary_image: {
          path: _product.image_url,
        },
        name: _product.product_name,
        id: _product.merchant_vintage_id,
        grape: null,
        region: null,
        category: null,
      }))
    }
    return res;
  })

}

const IntegrationsApi = new Integrations();
export { IntegrationsApi as default };
