class AppContext {
	constructor() {
		this.integration_id = null;
		this.product_id = null;
		this.lang = null;
		this.opts = null;
	}

	getProductId = () => this.product_id;
	getIntegrationId = () => this.integration_id;
	getLanguage = () => this.lang;
	getAppOptions = () => this.opts;

	setIntegrationId = (integration_id) => {
		this.integration_id = integration_id;
	}

	setProductId = (product_id) => {
		this.product_id = product_id;
	}

	setLanguage = (lang) => {
		this.lang = lang;
	}

	setAppOptions = (opts) => {
		this.opts = opts;
	}
}

const AppContextSingleton = new AppContext();

export default AppContextSingleton;
